body {
  display: flex;
  flex: 0 0 100%;
  flex-flow: column nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[hidden] {
  display: none !important;
}

@media (max-width: 767px) {
  html { font-size: 2.26365vw !important; }
}
