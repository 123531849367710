#ContactListPage {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1;
  flex-basis: 0px;
  overflow: hidden;
}

#ContactListPage > nav.menu {
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  grid-gap: 1em;
  align-content: stretch;
  margin: 1rem 0 2rem;
}
#ContactListPage > nav.menu::after { content: none; }

#ContactListPage > nav.menu > li.item {
  grid-row: 1;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

#ContactListPage > nav.menu > li.item > button.button {
  height: auto;
  color: black;
  box-shadow: 0 0 0 2px #222426 inset!important;
}

#ContactListPage > nav.menu > li.item {
  display: grid;
  grid-template-areas: 'button' 'label';
  align-items: stretch;
  justify-items: stretch;
  grid-gap: 0.375em;
}
#ContactListPage > nav.menu > li.item:not([aria-selected="true"]) {
  filter: saturate(0.75) brightness(1.25) opacity(0.75) blur(0.125px);
  font-weight: 500;
}
#ContactListPage > nav.menu > li.item:hover {
  filter: saturate(0.875) brightness(1.125);
  font-weight: 700;
}
#ContactListPage > nav.menu > li.item[aria-selected="true"]:focus,
#ContactListPage > nav.menu > li.item[aria-selected="true"]:focus-within {
  filter: brightness(1.125);
}
#ContactListPage > nav.menu > li.item[aria-selected="true"] > button {
  filter: drop-shadow(2px 4px 6px #0004);
}

#ContactListPage > nav.menu > li.item > button.button {
  margin: 0 !important;
  grid-area: button;
}

#ContactListPage > nav.menu > li.item > button.button > i.icon {
  font-size: 1.8em;
  line-height: .8;
}

/* provide an svg with the exact dimensions we want for the buttons, assigned to the same grid area */
#ContactListPage > nav.menu > li.item::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 10'%3E%3C/svg%3E");
  display: block;
  grid-area: button;
  z-index: -1;
  line-height: 0; /* needed for certain browsers */
}

#ContactListPage .dropdown[name="sort.order"] { text-transform: capitalize; }

#ContactListPage .dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-bottom: 0.25em;
  margin-bottom: -0.25em;
}

#ContactListPage > table {
  display: block;
  overflow: auto;
  flex: 0 1 auto;
  align-self: baseline;
  width: auto;
  max-width: 100%;
  border-collapse: separate;
}

#ContactListPage > table > thead > tr > th {
  position: sticky;
  top: 0px;
  border-right: 0;
  font-weight: 500;
}
#ContactListPage > table > thead > tr > th:hover,
#ContactListPage > table > thead > tr > th.sorted { background-color: #f2f2f2; }

#ContactListPage > table > thead > tr > th,
#ContactListPage > table > tbody > tr > td {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

#ContactListPage > table > tbody > tr:hover {
  cursor: pointer;
  font-weight: 500;
}
