#app {
  padding-top: 0.71428572rem;
  padding-bottom: 0.71428572rem;
  overflow: hidden;
  flex: 0 0 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

#app > header.PageHeader {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  padding-bottom: 0;
}

#app > header.PageHeader > .header {
  margin: 0;
}

#app > header.PageHeader > .header.PageTitle {
  flex: 1 1 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1em;
}

#app > header.PageHeader > .header.PageLogo {
  flex: 0 0 auto;
}
#app > header.PageHeader > .header.PageLogo::after { content: "\A0"; }

#app > header.PageHeader > i.icon {
  flex: 0 0 auto;
  align-self: center;
  cursor: pointer;
}

#app > main.PageBody {
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}