main#ContactDetailPage {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

main#ContactDetailPage > section {
  flex: 1 0 100px;
  display: grid;
  overflow: auto;
  margin: 0;
  padding: 1em 0.5em 2em;
  grid-template-areas: 'form aside';
  grid-template-columns: 1fr min-content;
  box-shadow: 0px -15px 15px -15px #22242626 inset;
  border-bottom: 1px solid #22242626;
}
main#ContactDetailPage > section > form { grid-area: form; }
main#ContactDetailPage > section > aside { grid-area: aside; position: static; }
main#ContactDetailPage > section::after { content: ""; grid-column-start: form-start; grid-column-end: aside-end; padding: 1em; }

@media (max-width: 767px) {
  main#ContactDetailPage > section { display: block; padding: 1em; }
  main#ContactDetailPage > section > aside { width: auto; margin: 2.5rem 0; padding: 2.5rem 0; border-left: 0; border-top: 1px solid rgba(34,36,38,.15); }
}

main#ContactDetailPage .dropdown > .text { white-space: nowrap; max-width: 100%; overflow: hidden; text-overflow: ellipsis; }
main#ContactDetailPage .checkbox > label { padding-left: calc(0.85714em + 17px); }

main#ContactDetailPage > section > aside.rail > section > header { cursor: pointer; margin: 0; white-space: nowrap; display: flex; justify-content: space-between; }
main#ContactDetailPage > section > aside.rail > section > header > i.icon { font-size: smaller; order: 2; margin-left: 1em; }
main#ContactDetailPage > section > aside.rail > section > header > .content { padding: 0; }

main#ContactDetailPage > section > aside.rail > section > ul { margin-left: 0; padding-left: 0; }
main#ContactDetailPage > section > aside.rail > section > ul > li { content: none; }
main#ContactDetailPage > section > aside.rail > section > ul > li::before { content: none; }
main#ContactDetailPage > section > aside.rail > section > ul > li i.icon { display: table-cell; font-size: inherit; height: 1em; width: 1em; color: #0006; }
main#ContactDetailPage > section > aside.rail > section > ul > li > .label { width: auto; }
main#ContactDetailPage > section > aside.rail > section > ul > li > .label > i.icon { padding-top: 0.5em; }
main#ContactDetailPage > section > aside.rail > section > ul > li > .content { margin-left: 0.5em; }

main#ContactDetailPage > section > aside.rail > section > form > .statistics { margin: 1em -1em; display: grid; grid-template-columns: 1fr 1fr; }
main#ContactDetailPage > section > aside.rail > section > form > .statistics > .statistic { margin-bottom: 0; }
main#ContactDetailPage > section > aside.rail > section > form > .statistics > .statistic > .button { margin: auto; padding: 0.125em 0.375em; color: black; box-shadow: 0 0 0 2px #222426 inset !important; }

main#ContactDetailPage > section > aside.rail > section > ul.feed > li.event > .content > .summary { font-weight: 500; }
main#ContactDetailPage > section > aside.rail > section > ul.feed > li.event > .content > .summary > .date { color: #0009; }
main#ContactDetailPage > section > aside.rail > section > ul.feed > li.event > .content > .meta { margin: 0; }

main#ContactDetailPage > footer { flex: 0 1 auto; margin: 0; padding: 1em 0 0; display: flex; flex-flow: column nowrap; overflow: hidden; }

main#ContactDetailPage > footer > h2.header { cursor: pointer; margin: 0; white-space: nowrap; display: flex; }
main#ContactDetailPage > footer > h2.header > i.icon { font-size: smaller; order: 2; }
main#ContactDetailPage > footer > h2.header > .content { padding: 0; }

/* TODO: move the menu out to its own component */
main#ContactDetailPage > footer > menu.Binder {
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  grid-gap: 1em;
  align-content: stretch;
  margin: 1rem 0 2rem;
  padding: 0;
}
main#ContactDetailPage > footer > menu.Binder::after { content: none; }

main#ContactDetailPage > footer > menu.Binder > li.item {
  grid-row: 1;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

main#ContactDetailPage > footer > menu.Binder > li.item > button.button {
  height: auto;
  color: black;
  box-shadow: 0 0 0 2px #222426 inset!important;
}

main#ContactDetailPage > footer > menu.Binder > li.item {
  display: grid;
  grid-template-areas: 'button' 'label';
  align-items: stretch;
  justify-items: stretch;
  grid-gap: 0.375em;
}
main#ContactDetailPage > footer > menu.Binder > li.item:not([aria-selected="true"]) {
  filter: saturate(0.75) brightness(1.25) opacity(0.75) blur(0.125px);
  font-weight: 500;
}
main#ContactDetailPage > footer > menu.Binder > li.item:hover {
  filter: saturate(0.875) brightness(1.125);
  font-weight: 700;
}
main#ContactDetailPage > footer > menu.Binder > li.item[aria-selected="true"]:focus,
main#ContactDetailPage > footer > menu.Binder > li.item[aria-selected="true"]:focus-within {
  filter: brightness(1.125);
}
main#ContactDetailPage > footer > menu.Binder > li.item[aria-selected="true"] > button {
  filter: drop-shadow(2px 4px 6px #0004);
}

main#ContactDetailPage > footer > menu.Binder > li.item > button.button {
  margin: 0 !important;
  grid-area: button;
}

main#ContactDetailPage > footer > menu.Binder > li.item > button.button > i.icon {
  font-size: 1.8em;
  line-height: .8;
}

/* provide an svg with the exact dimensions we want for the buttons, assigned to the same grid area */
main#ContactDetailPage > footer > menu.Binder > li.item::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 10'%3E%3C/svg%3E");
  display: block;
  grid-area: button;
  z-index: -1;
  line-height: 0; /* needed for certain browsers */
}

main#ContactDetailPage > footer.collapsed {
  height: 8.75em !important;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

main#ContactDetailPage > footer > form {
  flex: 1 1 auto;
  overflow: auto;
  margin: -1em 0 0;
  padding: 0.5em 0;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
main#ContactDetailPage > footer > form > section.column > h3.header > i.icon { font-size: smaller; width: auto; }

#app > div.frame { flex: 1 1 0; }
#app > nav.pagination { flex: 0 0 auto; box-shadow: 0 -5px 5px -5px; transform: translate(0); width: auto !important; }

.ListItemClickable {
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
  
}